.faq__block{
    margin-top: 50px;
    width: 100%;
    &__item{
        flex-direction: column;
        width: 100%;

        &_titleRight{
            position: relative;
            color: #212121;
            font-size: 26px;
            font-weight: 300;
            cursor: pointer;
            transition: .5s all;
            line-height: 44px;
        }

        &_titleRight::after{
            content: '';
            position: absolute;
            border: 10px solid transparent; 
            border-top: 10px solid #111111;
            right: 0%;
            top: 50%;
            transform: translateY(-50%)
        }

        &_titleBottom::after{
            content: '';
            position: absolute;
            border: 10px solid transparent; 
            border-left: 10px solid #111111;
            right: 0%;
            top: 50%;
            transform: translateY(-50%)
        }

        &_descr{
            margin-top: 30px;
            font-weight: 300;
            font-size: 20px;
            color: #4F4F4F;
        }
    }
}

@media only screen and (max-width: 500px) {
    .faq__block{

        margin-top: 40px;

        &__item{

            &_titleRight{
                position: relative;
                color: #212112;
                font-size: 24px;
                cursor: pointer;
                transition: .5s all;
            }
    
            &_titleRight::after{
                content: '';
                position: absolute;
                border: 6px solid transparent; 
                border-top: 10px solid #111111;
                right: 0%;
                top: 50%;
                transform: translateY(-50%)
            }
    
            &_titleBottom::after{
                content: '';
                position: absolute;
                border: 6px solid transparent; 
                border-left: 10px solid #111111;
                right: 0%;
                top: 50%;
                transform: translateY(-50%)
            }
    
            &_descr{
                margin-top: 12px;
                font-size: 20px;
                color: #4F4F4F;
            }
        }
    }
}