.oneProject{
    margin-top: 50px;
    &__item{
        width: 290px;

        height: 290px;
        position: relative;
        &_img{
            width: 100%;
            filter: grayscale(1);
            transition: .5s all;
        }
        &_img:hover{
            filter: grayscale(0)
        }
        &__wrapperText{
            position: absolute;
            min-height: 90px;
            bottom: 0;
            flex-direction: column;
            &_title{
                background-color: rgba(0,0,0, .75);
                color: #fff;
                text-align: center;
                padding: 25px 10px;
                font-weight: 500;
                font-size: 18px;
            }
            &_link{
                height: 50px;
                background-color: #E63946;
                text-align: center;
                font-weight: 600;
                font-size: 18px;
                line-height: 48px;
                color: #fff;
                display: none;
                cursor: pointer;
            }
        }
    }

}
.oneProject__item:hover{
        &_img{
            filter: grayscale(0)
        }
        .oneProject__item__wrapperText_link{
            display: block;
        }

}