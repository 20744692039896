.support{
    width: 100%;

    &__wrapper{

        flex-direction: column;

        &__main{
            background: url(../../images/jpg/about.jpg) center center/cover no-repeat;
            width: 100%;
            height: 510px;
            align-items: center;

            &__head{
                flex-direction: column;
                position: relative;
                width: 100%;

                &__title{
                    justify-content: space-between;
                    align-items: center;

                    &_h2{
                        font-weight: 400;
                        font-size: 32px;
                        line-height: 47px;
                        display: flex;
                        align-items: center;
                        color: #FFFFFF;
                        margin: 0px;
                    }

                    &_img{
                        width: 82px;
                        position: absolute;
                        right: 0px;
                        top: -12px;
                        display: block;
                    }

                    &_solidarity{
                        position: absolute;
                        right: 0px;
                        top: 50%;
                        transform: translateY(-50%);
                        display: block;
                    }
                }

                &_devider{
                    height: 5px;
                    width: 100%;
                    background-color: #E63946;
                    margin-top: 17px;
                    width: 50%;
                }

                &_text{
                    font-weight: 300;
                    font-size: 24px;
                    line-height: 28px;
                    letter-spacing: 0.01em;
                    color: #FFFFFF;
                    margin-top: 24px;
                    width: 50%;
                }
            }
        }

        &__crypto{
            margin-top: 80px;
            flex-direction: column;
            display: flex;

            &__wrap{
                justify-content: space-between;
                width: 100%;
                margin-bottom: 24px;
            }

            &__box{

                margin-top: 50px;

                &_title{
                    position: relative;
                    color: #828282;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 32px;
                    margin-right: 200px;
                }

                &_title::after{
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 3px;
                    background-color: #E63946;
                    bottom: -8px;
                }
            }

            &__list{
                flex-direction: column;
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .support{
        width: 100%;

    &__wrapper{

        flex-direction: column;

        &__main{
            background: url(../../images/jpg/bgSupport.webp) center center/cover no-repeat;
            width: 100%;
            height: calc(100vh - 40px);

            &__head{

                &__title{
                    justify-content: space-between;
                    align-items: center;

                    &_h2{
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 40px;
                        display: flex;
                        align-items: center;
                        color: #FFFFFF;
                        margin: 0px;
                    }

                    &_img{
                        width: 82px;
                        position: absolute;
                        right: 0px;
                        top: 0px;
                        display: none;
                    }

                    &_solidarity{
                        display: none;
                    }
                }

                &_devider{
                    height: 5px;
                    width: 100%;
                    background-color: #E63946;
                    margin-top: 17px;
                    width: 100%;
                }

                &_text{
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 28px;
                    letter-spacing: 0.01em;
                    color: #FFFFFF;
                    margin-top: 24px;
                    width: 100%;
                }
            }
        }

        &__crypto{
            margin-top: 40px;
            flex-direction: column;

            &__wrap{
                justify-content: space-between;
                width: 100%;
                margin-bottom: 24px;
                display: none;
            }

            &__box{

                margin-top: 50px;

                &_title{
                    position: relative;
                    color: #828282;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 32px;
                    margin-right: 200px;
                }

                &_title::after{
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 3px;
                    background-color: #E63946;
                    bottom: -8px;
                }
            }

            &__list{
                flex-direction: column;
            }
        }
    }
    }
}