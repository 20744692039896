.devider {

    flex-direction: column;
    width: 100%;

    pre {
        font-family: serif;
        color: #E63946;
        margin: 0px;
    }

    &__wrapper {

        justify-content: space-between;
        align-items: center;

        &__watchAll {

            align-items: center;
            cursor: pointer;

            &_text {
                font-size: 24px;
                margin-right: 32px;
                text-decoration: underline;
            }

            &_arrow {
                width: 50px;
            }
        }

        &__watchAll:hover .devider__wrapper__watchAll_text{
            color: #E63946;
        }

        &__watchAll:hover .devider__wrapper__watchAll_arrow{
            fill: #E63946;
        }
    }

    &__title {
        font-size: 48px;
        color: #212121;
        font-weight: 400;
        font-family: 'Oswald', sans-serif;
        display: flex;

        &_span {
            font-family: 'Oswald', sans-serif;
        }
    }

    &_devider {
        width: 100%;
        height: 5px;
        background-color: #212121;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 500px) {
    .devider {
        align-items: center;
    
        &__wrapper {
    
            justify-content: space-between;
            align-items: center;
    
            &__watchAll {
    
                display: none;
            }
        }
    
        &__title {
            font-size: 26px;
            color: #212121;
            font-weight: 400;
        }
    
        &_devider {
            width: 100%;
            height: 5px;
            background-color: #212121;
            margin-top: 20px;
        }
    }
    
}