.wrapper {
    width: 23%;
    display: flex;
    flex: 1 0 auto;
    max-width: 25%;

    &__mobile {
        display: none;
    }
}
.person {
    margin-top: 40px;
    height: 226px;
    width: 100%;
    margin-right: 2%;
    position: relative;
    cursor: pointer;
    background-position: center center;
    background-size: cover;

    &__wrapper{
        background: #0F2027;
        opacity: 0.9;
        border-radius: 0px 0px 1px 1px;
        width: 100%;
        position: absolute;
        padding: 2px 0px;
        color: #FFFFFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-weight: 300;
        font-size: 18px;
        line-height: 27px;
        bottom: 0px;
        justify-content: center;

        &_name{
            margin-left: 6px;
        }
    }

    &_devider{
        position: absolute;
        height: 5px;
        width: 100%;
        background-color: red;
        bottom: 0px;
        display: none;
        transition: .5s all;
    }

}

.person:hover .person_devider{
    display: block;
}

@media only screen and (max-width: 500px) {

    .wrapper {
        padding: 12px;
        width: 100%;
        background-color: #f5f5f5;
        border-top: 1px solid #bdbdbd;
        border-bottom: 1px solid #bdbdbd;
        text-decoration: none;
        font-size: 16px;
        color: #212121;
        font-weight: 400;        

        &__mobile {
            display: flex;
            margin-left: 10px;
            flex-direction: column;
            width: 75%;
            justify-content: space-between;
            position: relative;

            &__name {
                &_name {
                    margin-left: 6px;
                }
            }

            &__wrapperArrow {

                &_arrowRight {
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        display: inline-flex;
                        right: 0px;
                        top: 50%;
                        transform: translate(0%, -50%)
                    }
                
                    &_arrowRight::before,
                    &_arrowRight::after {
                        content: '';
                        position: absolute;
                    }
                
                    &_arrowRight::before {
                        width: 20px;
                        height: 3px;
                        background-color: #E63946;
                        top: 9px;
                    }
                
                    &_arrowRight::after {
                        width: 10px;
                        height: 10px;
                    }
                
                    &_arrowRight::after {
                        border-top: 3px solid #E63946;
                        border-right: 3px solid #E63946;
                        transform: rotate(45deg);
                        top: 3.5px;
                        right: 0px;
                    }
            }

        }
    }
    .person {
        margin-top: 0px;
        height: 138px;
        width: 112px;
        margin-right: 0px;

        &__wrapper {
            display: none;
        }

        &_devider {
            display: none;
            height: 0px;
        }
    }
}