.link{
    text-transform: lowercase;
    color: #E63946;
    text-decoration: none;
    margin-left: 16px;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    cursor: pointer;
    transition: .5s all;
}

.link::first-letter{
    text-transform: uppercase;
}

.link:hover{
    color: #A51924;
}