.base {
    margin: 35px 0px 0px 38px;
    width: 75%;
    flex-direction: column;

    &_mobileScreenWrapper {
        flex-direction: column;
    }

    &__search {
        height: 52px;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &__wrapper {
            display: none;
        }

        &_input {
            width: 75%;
            border: 1px solid #F4F4F4;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
            padding: 12px;
            outline:none;
        }

        &_button{
            width: 20%;
        }

        &_button:hover{
            background: linear-gradient(86.22deg, #E63946 0%, #AA2731 133.42%);
        }

        &_button:active{
            background: #AA2731;
        }

        &_button:disabled{
            background: grey;
        }
    }

    &__commonInfo {
        margin-top: 50px;
        display: flex;

        &_requests {
            font-weight: 600;
            font-size: 32px;
            line-height: 39px;
            color: #121212;

            span {
                margin-left: 12px;
                line-height: 47px;
            }
        }

        &_already {
            font-weight: 600;
            font-size: 32px;
            line-height: 39px;
            color: #121212;
            margin-left: 50px;

            span {
                margin-left: 12px;
                line-height: 47px;
                color: #E63946;
            }

        }
    }

    &__list{
        margin-top: 10px;
        flex-wrap: wrap;
    }

    &_pagination{
        width: 100%;
        margin-top: 40px;
        align-items: center;
        justify-content: center;
    }

    &_emptyWrapper {
        margin-top: 120px;
        justify-content: center;

        &_text {
            font-size: 32px;
            color: grey;
        }
    }

    &__loader {
        margin-top: 120px;
        width: 75%;
        justify-content: center;
        align-items: center;

        &_spin {
            border: 16px solid #f3f3f3;
            border-top: 16px solid #E63946;
            border-radius: 50%;
            width: 120px;
            height: 120px;
            animation: spin 2s linear infinite;
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .base {
        margin: 0px;
        width: 100%;
        align-items: center;

        &_mobileScreenWrapper {
            display: flex;
            min-height: 500px;
            margin-bottom: 60px;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            position: relative;
        }

        &__commonInfo {
            margin-top: 0px;
            display: none;
        }

        &__list{
            margin-top: 0px;
        }

        &__search {
            height: 66px;
            justify-content: start;
            align-items: baseline;

            &_button {
                display: none;
            }

            &_input {
                width: 65%;
                box-shadow: none;
                padding: 0px 12px;
                outline:none;
                height: 66px;
                margin-left: 40px;
            }

            &__wrapper {
                position: relative;
                width: 40px;
                height: 40px;
                margin: 0 auto;
                display: flex;

                &__icon {
                    position: relative;
                    width: 20px;
                    margin: 0 auto;
                    padding: 5px 0;
                    text-align: center;

                    &_circle {
                        width: 10px;
                        height: 10px;
                        border: 2px solid grey;
                        border-radius: 10px;
                    }

                    &_rectangle {
                        position: absolute;
                        right: 0;
                        bottom: 20px;
                        width: 7px;
                        transform: rotate(45deg);
                        border: 1px solid grey;
                        border-top-right-radius: 12px;
                        border-bottom-right-radius: 12px;
                    }
                }
            }
        }

        &_emptyWrapper {
            margin: 80px 0px;
            justify-content: center;
            position: absolute;
            right: 50%;
            transform: translate(50%, 0%);
            width: 100%;

            &_text {
                font-size: 22px;
                color: grey;
            }
        }

        &__loader {
            margin: 40px 0px;
            width: 50%;
            justify-content: center;
            align-items: center;

            &_spin {
                border: 4px solid #f3f3f3;
                border-top: 4px solid #E63946;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                animation: spin 2s linear infinite;
            }

            @keyframes spin {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }
        }
    }
}