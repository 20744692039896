.person {
    width: 100%;

    &__back{
        width: 100%;
        height: 50px;
        align-items: center;
        background-color: #E63946;
        display: flex;

        &_direction{
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            color: white;
            text-decoration: none;
        }

        &_img{
            transform: rotate(180deg);
            width: 20px;
            margin-right: 8px;
        }
    }

    &__wrapper {
        flex-direction: column;

        &__header{
            flex-direction: row;
            margin-top: 40px;

            &_photo{
                width: 280px;
                height: 315px;
            }

            &__basic{
                margin-left: 30px;
                width: 100%;
                flex-direction: column;

                &__main{
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    height: 48px;

                    &_name{
                        margin-left: 10px;
                        text-transform: lowercase;
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 47px;
                        color: #121212;
                        font-family: 'Oswald';
                    }

                    &_name::first-letter{
                        text-transform: uppercase;
                    }

                    &_name:first-child{
                        margin-left: 0px;
                    }

                    &__complain{
                        cursor: pointer;
                    }

                    &_error{
                        font-weight: 300;
                        font-size: 24px;
                        line-height: 36px;
                        color: #E63946;
                    }

                    &_img{
                        width: 36px;
                        height: 36px;
                        margin-left: 12px;
                    }

                }

                &_text{
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 36px;
                    color: #121212;
                    margin-top: 15px;
                    font-family: 'Oswald';

                    &_space {
                        margin-left: 6px;
                    }

                    &_colorRed {
                        margin-left: 6px;
                        color:#E63946
                    }
                }

                &_social{
                    align-items: baseline;
                }
            }
        }

        &__content{
            margin-top: 40px;
            background-color: #F4F4F4;
            padding: 20px 35px 100px;
            flex-direction: column;

            &_parser{
                flex-direction: column;
                display: flex;
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .person {

        &__back {
            display: none;
        }

        &__wrapper {

            &__header {
                flex-direction: column;
                align-items: center;

                &_photo {
                    width: 100%;
                    height: auto;
                }

                &__basic {

                     &_text{
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 36px;
                        color: #121212;
                        margin-top: 8px;
                        font-family: 'Oswald';

                        &_space {
                            margin-left: 6px;
                        }

                        &_colorRed {
                            margin-left: 6px;
                            color:#E63946
                        }
                    }
                }
            }
        }
    }
}