.base{
    width: 100%;

    &__wrapper{

        flex-direction: column;

        &__main{
            background: url(../../images/jpg/basePolice.jpg) center center/cover no-repeat;
            width: 100%;
            height: 510px;
            align-items: center;

            &__head{
                flex-direction: column;
                position: relative;
                width: 100%;

                &__title{
                    justify-content: space-between;
                    align-items: center;

                    &_h2{
                        font-weight: 400;
                        font-size: 32px;
                        line-height: 47px;
                        display: flex;
                        align-items: center;
                        color: #FFFFFF;
                        margin: 0px;
                    }

                    &_img{
                        width: 82px;
                        position: absolute;
                        right: 0px;
                        top: -12px;
                    }

                    &_solidarity{
                        position: absolute;
                        right: 0px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }

                &_devider{
                    height: 5px;
                    width: 100%;
                    background-color: #E63946;
                    margin-top: 17px;
                    width: 50%;
                }

                &_text{
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 28px;
                    letter-spacing: 0.01em;
                    color: #FFFFFF;
                    margin-top: 24px;
                    width: 50%;
                }
            }
        }

        &__content{
            flex-direction: row;
            width: 100%;
        }
    }
}

@media only screen and (max-width: 500px) {
    .base {
        &__wrapper {

            &__main {
                display: none;
            }
        }
    }
}