.investigation{
    overflow: visible;
    overflow-x: scroll;
    &__wrapper{
        flex-direction: row;
        flex-wrap: nowrap;
        &__button {
            display: none;
        }
    }
}

@media only screen and (max-width: 500px) {
    .investigation{
        overflow: hidden;
        overflow-x: scroll;
        flex-direction: column;
        &__wrapper{
            flex-direction: column;

            &__button {
                width: 100%;
                margin-top: 40px;

                &_text{
                    text-decoration: none;
                    color: white;
                }
            }
        }
    }
    .scroll{
        flex-direction: column;
    }
}