.about{
    width: 100%;

    &__wrapper{

        flex-direction: column;

        &__main{
            background: url(../../images/jpg/baseBg.jpg) center center/cover no-repeat;
            width: 100%;
            height: 510px;
            align-items: center;

            &__head{
                flex-direction: column;

                &__title{
                    justify-content: space-between;
                    align-items: center;

                    &_h2{
                        font-weight: 400;
                        font-size: 32px;
                        line-height: 47px;
                        display: flex;
                        align-items: center;
                        color: #FFFFFF;
                        margin: 0px;
                    }

                    &_img{
                        width: 82px;
                    }
                }

                &_devider{
                    height: 5px;
                    width: 100%;
                    background-color: #E63946;
                    margin-top: 17px;
                }

                &_text{
                    font-weight: 300;
                    font-size: 24px;
                    line-height: 28px;
                    letter-spacing: 0.01em;
                    color: #FFFFFF;
                    margin-top: 24px;
                }
            }
        }

        &__about{
            justify-content: space-between;
            align-items: center;
            margin-top: 100px;

            &_img{
                width: 45%;
            }

            &__text{
                width: 45%;
                flex-direction: column;

                &_descr{
                    margin-top: 50px;
                    font-weight: 300;
                    font-size: 24px;
                    line-height: 32px;
                    letter-spacing: -0.01em;
                    color: #121212;
                }
            }
        }

        &__notice{
            margin-top: 150px;
            flex-direction: column;

            &__wrap{

                width: 100%;
                margin-top: 16px;
                flex-wrap: wrap;
                justify-content: space-between;

                &_component{
                    width: 48%;
                    height: 100px;
                    margin-top: 16px;
                    display: flex;
                    flex-direction: row;
                    text-decoration: none;
                    justify-content: space-between;

                    &_img {
                        height: 100%;
                        width: 20%;
                    }

                    &_text {
                        padding: 12px 0px;
                        width: 75%;
                        font-size: 20px;
                        font-weight: 600;
                        color: #212121;
                    }
                }

                &_component:hover {
                    background-color: rgba(0,0,0, 0.4);
                }
            }
        }

        &__partners{
            margin-top: 120px;
            flex-direction: column;

            &__wrap{
                margin-top: 24px;
                flex-wrap: wrap;
                justify-content: space-between;

                &_component{
                    cursor: pointer;
                    display: flex;
                    align-items: center;

                    &_img {
                        width: 200px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .about{
    
        &__wrapper{

            &__main{
                background: url(../../images/jpg/about.webp) center center/cover no-repeat;
                width: 100%;
                height: calc(90vh - 40px);

                &__head{
    
                    &__title{
                        justify-content: space-between;
                        align-items: center;
    
                        &_h2{
                            font-weight: 400;
                            font-size: 28px;
                            line-height: 40px;
                            display: flex;
                            align-items: center;
                            color: #FFFFFF;
                            margin: 0px;
                        }
                    }
    
                    &_devider{
                        height: 5px;
                        width: 100%;
                        background-color: #E63946;
                        margin-top: 24px;
                    }
    
                    &_text{
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 28px;
                        letter-spacing: 0.01em;
                        color: #FFFFFF;
                    }
                }
            }
    
            &__about{

                margin-top: 40px;
                width: 100%;
                flex-direction: column;
                
                &_img{
                    width: 100%;
                    margin-top: 24px;
                }
    
                &__text{
                    width: 100%;
                    flex-direction: column;
    
                    &_descr{
                        margin-top: 24px;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 32px;
                        letter-spacing: -0.01em;
                        color: #121212;
                    }
                }
            }
    
            &__notice{
                margin-top: 40px;
                flex-direction: column;
    
                &__wrap{
    
                    width: 100%;
                    margin-top: 16px;
                    flex-wrap: wrap;
                    justify-content: space-between;
    
                    &_component {
                        width: 100%;
                        height: 100px;
                        margin-top: 16px;
                        display: flex;
                        flex-direction: row;
                        text-decoration: none;
                        justify-content: space-between;
                
                        &_img {
                            height: 100%;
                            width: 20%;
                        }
                
                        &_text {
                            padding: 12px 0px;
                            width: 75%;
                            font-size: 1px;
                            font-weight: 600;
                            color: #212121;
                        }
                    }
                }
            }
    
            &__partners{
                margin-top: 40px;
                flex-direction: column;
    
                &__wrap{
                    margin-top: 24px;
                    flex-wrap: wrap;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    display: flex;
    
                    &_component{
                        width: 100%;
                        margin-top: 40px;
                        display: flex;
                        justify-content: center;
                    }

                    &_component:first-child {
                        margin-top: 0px;
                    }
                }
            }
        }
    }
}