.mainPage{
    width: 100%;
    height: 688px;
    background: url(../../images/jpg/mainBg.jpg) center center/cover no-repeat;
    position: relative;
    z-index: 1;

    &__wrapper, &__wrapperText{
        flex-direction: column;
    }

    &__mainIMG{
        width: 994px;
        height: 612px;
        position: absolute;
        bottom: 0px;
        right: 100px;
        z-index: -1000;
        opacity: .9;
    }

    h1{
        font-size: 32px;
        color: #fff;
        margin: 0px;
        padding-top: 105px;
        font-family: 'Oswald', sans-serif;
        font-weight: 400;
        line-height: normal;
    }

    &__devider{
        width: 577px;
        height: 5px;
        background-color: #E63946;
        margin-top: 17px;
    }

    &__descr{
        width: 577px;
        font-size: 24px;
        font-weight: 300;
        letter-spacing: -0.2px;
        color: #fff;
        margin-top: 25px;
    }

    &__descrMobile, &__mobileButtons {
        display: none;
    }
    &__wrapperButtons{
        margin-top: 40px;
        transition: .5s all;

        &_button{
            cursor: pointer;
            font-size: 18px;
            width: 175px;
            height: 45px;
            font-weight: 400;
        }

        &_button:hover{
            background: linear-gradient(86.22deg, #E63946 0%, #AA2731 133.42%);
        }

        &_button:active{
            background: #AA2731;
        }

        &__youtube{
            display: flex;
            margin-left: 35px;
            align-items: center;
            cursor: pointer;
            text-decoration: none;

            &_text{
                color: #fff;
                font-size: 24px;
                margin-left: 18px;
                font-weight: 300;
            }
        }

        &__youtube:hover div{
            color: #E63946;
        }

        &__youtube:hover .mainPage__wrapperButtons__youtube_img{
            stroke: #E63946;
        }

        &__youtube:active div{
            color: #AA2731;
        }

        &__youtube:active .mainPage__wrapperButtons__youtube_img{
            stroke: #AA2731;
        }
    }
}

@media only screen and (max-width: 500px) {
    .mainPage{
        height: calc(100vh - 150px);
        position: relative;
        z-index: 1;

        &__wrapper{
            height: calc(100vh - 180px);
            display: flex;
            justify-content: space-between;
            flex-direction: column;
        }

        &__mainIMG{
            width: 100%;
            height: 40vh;
            position: absolute;
            bottom: 130px;
            right: 0px;
            z-index: -1000;
        }

        &__wrapperText{
            flex-direction: column;
        }

        h1{
            font-size: 24px;
            color: #fff;
            margin: 0px;
            padding-top: 80px;
        }

        &__devider{
            width: 100%;
            height: 5px;
            background-color: #E63946;
            margin-top: 24px;
        }

        &__descr{
            display: none;
        }

        &__descrMobile{
            display: flex;
            width: 100%;
            font-size: 26px;
            font-weight: 400;
            letter-spacing: -3%;
            color: #fff;
            margin-top: 25px;
        }

        &__wrapperButtons{
            display: none;
        }

        &__mobileButtons{
            display: flex;
            flex-direction: column;

            &_buttonYoutube, &_button{
                width: 100%;

                &_text{
                    text-decoration: none;
                    color: white;
                }
            }

            &_buttonYoutube {
                margin-bottom: 24px;
            }

            &_button {
                background-color: inherit;
                border: 2px solid white;
            }
        }
    }
}