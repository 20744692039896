.social {

    margin-top: 114px;

    &__wrapper {

        margin-top: 50px;
        justify-content: space-between;

        &__info {

            width: 510px;
            flex-direction: column;

            &_text {

                font-size: 24px;
                font-weight: 300;
            }

            &_button {

                margin-top: 32px;
                width: 220px;
                background: #E63946;
                display: block;
            }

            &_button:hover {
                background: linear-gradient(86.22deg, #E63946 0%, #AA2731 133.42%);
            }

            &_button:active {
                background: #AA2731;
            }
        }

        &__item {
            
            width: 150px;
            height: 150px;
            background-color: #FBFBFB;
            justify-content: center;
            align-items: center;
            position: relative;
            margin-left: 40px;

            &_img {
                width: 86px;
                height: 86px;
                filter: grayscale(1);
                transition: .5s all;
            }
        }
    }
}
.social__wrapper__item::after {

    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: red;
    bottom: 0;
    display: none;
}
.social__wrapper__item:hover {

    .social__wrapper__item_img {
        filter: grayscale(0);
    }

}
.social__wrapper__item:hover::after {

    display: block;
}

@media only screen and (max-width: 500px) {
    .social {
        margin-top: 40px;

        &__wrapper {
            margin-top: 24px;
            justify-content: space-between;
            flex-direction: column;

            &__info {

                width: 100%;
    
                &_text {
    
                    font-size: 20px;
                }
    
                &_button {
    
                    display: none;
                }
            }

            &__item {
            
                width: 50%;
                height: 150px;
                background-color: #FBFBFB;
                justify-content: center;
                align-items: center;
                position: relative;
                margin-left: 0px;
                margin-top: 40px;
    
                &_img {
                    width: 86px;
                    height: 86px;
                    filter: grayscale(0);
                }
            }

            &__imgs {
                flex-wrap: wrap;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                width: 100%;
            }
        }
    }
}