.menu{
    width: 25%;
    background-color: #F4F4F4;
    min-height: 100px;
    flex-direction: column;
    padding: 50px 30px 0px;
    box-sizing: border-box;
    display: flex;   
}

.burgerSearch {
    display: none;
}

@media only screen and (max-width: 500px) {
    .menu {
        display: none;
    }

    .burgerSearch {
        display: flex;
        position: absolute;
        // background-color: red;
        height: 66px;
        width: 40px;

        &_menu {
            position: relative;
            width: 30px;
            height: 20px;
            // background-color: red;
            top: 50%;
            transform: translate(0%, -50%);

            span {
                position: absolute;
                background-color: #b2b2b2;
                left: 0px;
                width: 100%;
                height: 2px;
                top: 9px;
            }
        }

        &_menu::before,
        &_menu::after {
            content: '';
            background-color: #b2b2b2;
            width: 100%;
            height: 2px;
            left: 0px;
            position: absolute;
        }

        &_menu::before {
            top: 0px;
        }

        &_menu::after {
            bottom: 0px;
        }

        &__content {
            position: fixed;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-color: #fff;
            z-index: 10000;
            display: flex;

            &_close {
                position: absolute;
                right: 32px;
                top: 32px;
                width: 32px;
                height: 32px;
                display: block;
            }
            &_close::before, &_close::after {
                position: absolute;
                left: 15px;
                content: ' ';
                height: 33px;
                width: 2px;
                background-color: black;
            }
            &_close::before {
                transform: rotate(45deg);
            }
            &_close::after {
                transform: rotate(-45deg);
            }

            &__wrapper {
                flex-direction: column;
                margin: 40px;
            }

            &_closed {
                display: none;
            }
        }
    }
}