.crypto{
    width: 100%;
    justify-content: space-between;
    margin-top: 16px;

    &__name{
        align-items: center;

        &_img{
            width: 24px;
        }

        &_title{
            color: #0F2027;
            letter-spacing: -0.01em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            font-weight: 400;
            font-size: 24px;
            line-height: 32px;
            margin-left: 8px;
        }
    }

    &__address{
        align-items: center;
        width: 380px;
        justify-content: space-between;

        &_img{
            width: 32px;
            cursor: pointer;
        }

        &_qr{
            display: block;
        }

        &_title{
            color: #0F2027;
            letter-spacing: -0.01em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            font-weight: 400;
            font-size: 24px;
            line-height: 32px;
            margin-right: 0px;
        }
    }
}

@media only screen and (max-width: 500px) {
    .crypto{
        width: 100%;
        justify-content: space-between;
        margin-top: 32px;
    
        &__name{
            align-items: center;
    
            &_img{
                width: 24px;
            }
    
            &_title{
                color: #0F2027;
                letter-spacing: -0.01em;
                font-feature-settings: 'pnum' on, 'lnum' on;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                margin-left: 8px;
            }
        }
    
        &__address{
            align-items: center;
            width: auto;
            justify-content: space-between;
    
            &_img{
                width: 32px;
                cursor: pointer;
            }

            &_qr{
                display: none;
            }
    
            &_title{
                color: #0F2027;
                letter-spacing: -0.01em;
                font-feature-settings: 'pnum' on, 'lnum' on;
                font-weight: 400;
                font-size: 16px;
                line-height: 32px;
                margin-right: 16px;
            }
        }
    }
}