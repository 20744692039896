.header{
    width: 100%;
    height: 80px;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.10);
    z-index: 100;
    position: relative;

    &__mobileMenu{
        display: none;
    }

    &__burgerMenu{
        display: none;
    }

    &__mobileWrapper {
        display: none;
    }

    &__wrapper{
        justify-content: space-between;
        width: 100%;
        align-items: center;
        position: relative;

        &__share{
            position: absolute;
            height: 188px;
            background-color:white;
            right: 0px;
            bottom: -184px;
            z-index: 100;
            align-items: center;
            padding-left: 30px;
            padding-right: 88px;

            &__wrapper{
                flex-direction: column;

                &_title{
                    font-weight: 400;
                    font-size: 36px;
                    font-family: 'Oswald', sans-serif;

                    span{
                        color: #E63946;
                    }
                }

                &__images{
                    margin-top: 24px;

                    &_container {
                        margin-right: 24px;
                    }

                    button{
                        width: 60px;
                        margin-left: 30px;
                    }

                    button:first-child{
                        width: 60px;
                        margin-left: 0px;
                    }

                    &_img{
                        width: 60px;
                        height: 60px;
                        cursor: pointer;
                        transition: .5s all;

                        &_copy:hover{
                            fill: #CECECE;
                        }

                        &_facebook:hover{
                            fill: #304F8E;
                        }

                        &_instagram:hover{
                            fill: #9F3768;
                        }

                        &_youtube:hover{
                            fill: #AF2630;
                        }

                        &_telegram:hover{
                            fill: #2078AF;
                        }
                    }

                    &_img:first-child{
                        margin-left: 0px;
                    }
                }
            }

            &_close{
                position: absolute;
                top: 20px;
                right: 20px;
                cursor: pointer;
                transition: .5s all;
            }

            &_close:hover{
                fill: #BEBBBB;
            }
        }
    }

    &__wrapperLinks{
        flex-direction: row;
        display: flex;
    }

    &__mainLogo{
        width: 55px;
        height: 55px;
        width: 80px;
        height: 80px;
        cursor: pointer;
    }

    &__mainLogo:hover{
        fill: #E63946;
    }

    &__styleLinks{
        flex-direction: row;
        display: flex;
        align-items: center;

        &_link{
            margin-left: 50px;
            cursor: pointer;
            font-size: 18px;
            font-weight: 600;
            position: relative;

            &_text{
                color: #212121;
                text-decoration: none;

                &_active{
                    text-decoration: none;
                    position: relative;
                    color: #212121;
                }

                &_active::after{
                    content: '';
                    position: absolute;
                    width: calc(100% + 20px);
                    height: 3px;
                    background-color: #E63946;
                    top: 48px;
                    left: -10px;
                }
            }

            &_btn{

                color: #fff;
                background-color: #E63946;
                width: 165px;
                height: 42px;
                font-weight: 400;
                font-size: 18px;
                text-transform: uppercase;
                border: none;
                cursor: pointer;
                font-family: 'ProximaNova', sans-serif;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                
                &_text{
                    text-decoration: none;
                }
            }

            &_btn:hover{
                background: linear-gradient(86.22deg, #E63946 0%, #AA2731 133.42%);
            }

            &_btn:active{
                background: #AA2731;
            }
        }

        &_link:first-child{
            margin-left: 65px;
        }

        &_link:after{
            content: '';
            display: none;
            width: calc(100% + 20px);
            height: 3px;
            background-color: #E63946;
            position: absolute;
            top: 48px;
            left: -10px;
        }

        &_link:hover:after{
            display: block;
        }

    }

    &__wrapperButtons{
        align-items:center;

        &_youtube{
            width: 32px;
            height: 32px;
            transition: .5s all;
            margin-top: 6px;
        }

        &_youtube:hover{
            stroke: #E63946;
        }

        &__popup{
            
            &_share{
                width: 22px;
                height: 25px;
                margin: 0px 45px;
                cursor:pointer;
                transition: .5s all;
            }

            &_share:hover{
                stroke: #E63946;
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .header {

        z-index: 10000;

        &__wrapper {
            display: none;
        }

        &__mobileWrapper {
            padding: 0px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            width: 100%;

            &__leftPart {

                align-items: center;

                &_title{
                    margin-left: 16px;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }

            &__rightPart {

                position: relative;
                height: 100%;
                width: 24px;
                align-items: center;

                &__wrapper {
                    display: flex;
                    width: 40px;
                    height: 40px;
                    justify-content: center;
                }

                span, span::before, span::after{
                    position: absolute;
                    width: 24px;
                    height: 2px;
                    background-color: #212121;
                    z-index: 10000;
                }

                span::before{
                    content: '';
                    top: -8px;
                }

                span::after{
                    content: '';
                    top: 8px;
                }
            }
        }

        &__burgerMenu{
            height: 100vh;
            width: 100vw;
            background-color: #212121;
            position: relative;
            padding: 0px 20px 130px;
            box-sizing: border-box;
            display: flex;

            &_close {
                position: absolute;
                display: flex;
                align-items: center;
                height: 80px;
                right: 20px;
                width: 24px;

                span, span::before, span::after{
                    position: absolute;
                    width: 24px;
                    height: 2px;
                    background-color: white;
                    transform: rotate(45deg);
                }
    
                span::before{
                    content: '';
                    top: 0px;
                    transform: rotate(90deg);
                }
    
                span::after{
                    content: '';
                    top: 0px;
                    transform: rotate(0deg);
                }
            }

            &__wrapper {

                flex-direction: column;
                justify-content: space-between;
                width: 100%;

                &_upperSide {
                    padding-top: 10px;
                    flex-direction: column;
                }

                &_title{
                    color: white;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }

                &__nav {

                    &__styleLinks {

                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;

                        &_link{
                            margin: 12px 0px;
                            cursor: pointer;
                            font-size: 18px;
                            font-weight: 600;
                            position: relative;

                            &_text{
                                color: white;
                                text-decoration: none;

                                &_active{
                                    text-decoration: none;
                                    color: grey;
                                }
                            }
                        }
                    }
                }

                &_downSide {
                    flex-direction: column;
                    align-items: center;

                    &_btn {
                        color: #fff;
                        background-color: #E63946;
                        width: 100%;
                        height: 42px;
                        font-weight: 400;
                        font-size: 18px;
                        text-transform: uppercase;
                        border: none;
                        cursor: pointer;
                        font-family: 'ProximaNova', sans-serif;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-decoration: none;

                        &_text{
                            text-decoration: none;
                            color: #fff;
                        }
                    }
                }

                &__socialLinks {

                    a{
                        margin: 40px;
                    }
                }
            }
        }
    }
}