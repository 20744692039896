.element{
    margin-top: 16px;
    align-items: center;

    &_checkbox{
        width: 22px;
        height: 22px;
        border-radius: 1px;
        border: 1px solid #E63946;
        border-radius: 1px;
        cursor: pointer;
        
        &_active{
            position: relative;
        }

        &_active::after{
            content: '';
            position: absolute;
            background: #E63946;
            width: 12px;
            height: 12px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 1px;
        }
    }

    &_text{
        margin-left: 12px;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #121212;
    }
}