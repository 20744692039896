.pagination{
    justify-content: center;
    margin-top: 60px;

    &_active {
        width: 42px;
        height: 42px;
        background: #E63946;
        border-radius: 1px;
        align-items: center;
        justify-content: center;
        cursor: default;
        color: white;
    }

    &_number {
        width: 42px;
        height: 42px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    &_number:hover{
        background-color: #CBCBCB;
    }
}