.donate{
    margin-top: 114px;
    position: relative;

    &__wrapper{
        justify-content: space-between;
        margin-top: 50px;

        &__money{
            width: 48%;
            flex-direction: column;
            align-items:center;

            &__wrapperDevider{
                flex-direction: row;
                justify-content: space-between;
                width: 100%;

                &_devider{
                    width: 30%;
                    background-color: #E63946;
                    height: 5px;
                }
            }

            &_title{
                margin-top: 55px;
                font-size: 24px;
            }

            &__wrapperAmount{
                flex-direction: row;
                margin-top: 32px;
                justify-content: space-between;
                width: 100%;

                &_amount{
                    width: 18%;
                    background-color: #E63946;
                    height: 65px;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    font-size: 24px;
                    border: solid 1px #F2F2F2;
                    cursor: pointer;
                }
            }

            &__wrapperInput{
                width: 100%;
                &_input{
                    width: 100%;
                    border: solid 1px #F2F2F2;
                    height: 65px;
                    font-size: 24px;
                    padding: 0px 20px;
                    margin-top: 32px;
                }
            }

            &_button{
                width: 237px;
                margin-top: 40px;
                padding: 10px 0px;
            }
        }

        &__crypto{
            width: 100%;
            flex-direction: column;
            align-items:center;

            &_devider{
                height: 5px;
                width: 100%;
                background-color: #E63946;
                display: flex;
            }

            &_title{
                margin-top: 55px;
                font-size: 28px;
            }

           &__blockCurrency{
               flex-direction: column;
                width: 100%;

               &__item{
                   width: 100%;
                   justify-content: space-between;
                   margin-top: 30px;
                   align-items: center;

                   &_title{
                       font-size: 24px;
                       color:#000
                   }

                    &_img {
                        display: block;
                    }

                   &__buttons{
                       flex-direction: row;
                       &_img{
                            width: 33px;
                            height: 45px;
                       }
                       &_qr{
                           width: 54px;
                           height: 54px;
                           background-color: #c4c4c4;
                           margin-left: 40px;
                           display: block;
                       }
                   }
               }
           }
        }
    }
}

@media only screen and (max-width: 500px) {
    .donate{

        margin-top: 40px;

        &__wrapper{

            margin-top: 16px;
            
            &__money{
                display: none;
            }

            &__crypto{
                width: 100%;
                flex-direction: column;
                align-items:center;

                &_devider{
                    display: none;
                }

                &_title{
                    margin-top: 0px;
                    font-size: 24px;
                }

                &_img {
                    display: none;
                }
            }
        }
    }
}