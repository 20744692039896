$whiteColor: #fff;
$redColor: #E63946;
$blackColor: #212121;
body {
  margin: 0px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  background-color: white;
  color: #212121;
  font-family: 'ProximaNova', sans-serif;
}
.g-container {
 width: 1280px;
 padding: 0px 20px;
 margin: 0 auto;
 flex-direction: column;
}
div {
  display: flex;
}
button {
  color: $whiteColor;
  background-color: $redColor;
  width: 155px;
  height: 42px;
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-family: 'ProximaNova', sans-serif;
}
.g-wrapperPage{
  width: 100%;
  flex-direction: column;
  overflow-x: hidden;
}
ul{
  margin: 0;
  padding: 0;
}
li {
  list-style-type: none;
}
p {
  margin: 0px;
}

@media only screen and (max-width: 500px) {
  .g-container {
    width: 100%;
    box-sizing: border-box;
  }
}