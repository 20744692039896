.item{
    margin-top: 50px;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
    display: flex;
    text-decoration: none;

    &_img{
        width: 35%;
        height: 290px;
    }

    &__wrapper{
        width: 60%;
        flex-direction: column;

        &_title{
            font-weight: 400;
            font-size: 32px;
            line-height: 47px;
            color: #000000;
        }

        &_descr{
            font-weight: 400;
            font-size: 24px;
            line-height: 29px;
            color: #4F4F4F;
            margin-top: 32px;
        }
    }
}

@media only screen and (max-width: 500px) {
    .item{
        margin-top: 40px;
        width: 100%;
        justify-content: space-between;
        cursor: pointer;
        display: flex;
        text-decoration: none;
        flex-direction: column;
    
        &_img{
            width: 100%;
            height: 290px;
        }
    
        &__wrapper{
            width: 100%;
            flex-direction: column;
    
            &_title{
                font-weight: 400;
                font-size: 24px;
                line-height: 32px;
                color: #000000;
                margin-top: 20px;
                text-decoration: underline solid black;
            }
    
            &_descr{
                font-weight: 400;
                font-size: 20px;
                line-height: 29px;
                color: #4F4F4F;
                margin-top: 16px;
            }
        }
    }
}