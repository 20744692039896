.section{
    margin-bottom: 50px;
    flex-direction: column;

    &_title{
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #121212;
    }

    &_devider{
        width: 100%;
        height: 3px;
        background-color: #E63946;
        margin-top: 10px;
    }
}