.video{
    width: 100%;

    &__wrapperVideoCover {

        justify-content: center;
        align-items: center;
        border-width: 1px;
        border-style: solid;
        border-image: linear-gradient(120deg, #E63946, rgba(230, 57, 70, 0)) 0.5;

        &_playVideo {
            width: 102px;
            height: 102px;
            border: 3px white solid;
            border-radius: 100px;

            path {
                fill: white;
            }
        }
    }

    &__wrapperVideoCover:hover .video__wrapperVideoCover_playVideo{

        border: 3px #E63946 solid;
    
        path {
            fill: #E63946;
        }
    }

    &__wrapper{

        flex-direction: column;

        &__main{
            width: 100%;
            height: 680px;
            align-items: center;
            z-index: 10;
            position: relative;

            &_bg{
                z-index: -1;
                background: linear-gradient(90deg, #0F1627 0%, #202A43 52.08%, #242A3E 100%);
                opacity: 0.75;
                width: 100%;
                height: 680px;
                position: absolute;
            }

            &__head{
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                align-items: center;

                &__left{

                    flex-direction: column;
                    width: 50%;

                    &__wrapper {
                        flex-direction: column;
                        justify-content: space-between;
                    }

                    &__title{
                        justify-content: space-between;
                        align-items: center;
    
                        &_h2{
                            display: block;
                            font-weight: 400;
                            font-size: 32px;
                            line-height: 47px;
                            display: flex;
                            align-items: center;
                            color: #FFFFFF;
                            margin: 0px;
                        }

                        &_h2Mobile {
                            display: none;
                        }
                    }
    
                    &_devider{
                        height: 5px;
                        background-color: #E63946;
                        margin-top: 17px;
                        width: 100%;
                    }
    
                    &_text{
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 28px;
                        letter-spacing: 0.01em;
                        color: #FFFFFF;
                        margin-top: 24px;
                        width: 100%;
                    }

                    &__btn{
                        margin-top: 36px;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 22px;
                        color: #FFFFFF;

                        &_primary{
                            background-color: #E63946;
                        }

                        &_primary:hover{
                            background: linear-gradient(86.22deg, #E63946 0%, #AA2731 133.42%);
                        }
    
                        &_primary:active{
                            background: #AA2731;
                        }

                        &_support {
                            display: none;
                        }

                        &__youtube{
                            display: flex;
                            margin-left: 35px;
                            align-items: center;
                            cursor: pointer;
                            text-decoration: none;
                            display: flex;

                            &_img {
                                display: block;
                            }
                
                            &_text{
                                color: #fff;
                                font-size: 24px;
                                margin-left: 18px;
                            }
                        }
                
                        &__youtube:hover div{
                            color: #E63946;
                        }
                
                        &__youtube:hover .video__wrapper__main__head__left__btn__youtube_img{
                            stroke: #E63946;
                        }
                
                        &__youtube:active div{
                            color: #AA2731;
                        }
                
                        &__youtube:active .video__wrapper__main__head__left__btn__youtube_img{
                            stroke: #AA2731;
                        }
                    }
                }

                &__right{
                    
                    position: relative;
                    cursor: pointer;
                    display: flex;

                    &_img{
                        width: 564px;
                        height: 372px;
                        border-radius: 1px;
                    }

                    &_play{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%)
                    }
                }
            }
        }

        &__content{
            margin-top: 80px;
            flex-direction: column;

            &__investigation{
                flex-direction: column;
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .video{
    
        &__wrapper{
    
            &__main{
                width: 100%;
                height: calc(100vh - 40px);
    
                &__head{
                    flex-direction: row;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
    
                    &__left{
    
                        flex-direction: column;
                        width: 100%;
                        justify-content: space-between;
                        height: calc(100vh - 320px);

                        &__wrapper {
                            flex-direction: column;
                            justify-content: space-between;
                        }
    
                        &__title{
                            justify-content: center;
                            align-items: center;
        
                            &_h2{
                                display: none;
                            }
                            &_h2Mobile {
                                display: block;
                                font-weight: 400;
                                font-size: 28px;
                                line-height: 47px;
                                display: flex;
                                align-items: center;
                                color: #FFFFFF;
                                margin: 0px;
                            }
                        }
        
                        &_devider{
                            height: 5px;
                            background-color: #E63946;
                            margin-top: 17px;
                            width: 100%;
                        }
        
                        &_text{
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 28px;
                            letter-spacing: 0.01em;
                            color: #FFFFFF;
                            margin-top: 24px;
                            width: 100%;
                        }
    
                        &__btn{
                            margin-top: 36px;
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 22px;
                            color: #FFFFFF;
                            flex-direction: column;
    
                            &_primary{
                                background-color: #E63946;
                                width: 100%;
                            }

                            &_support {
                                display: flex;
                                background-color: inherit;
                                border: 2px solid white;
                                height: 42px;
                                justify-content: center;
                                align-items: center;
                                text-decoration: none;
                                color: white;
                                font-size: 18px;
                                font-weight: 600;
                                text-transform: uppercase;
                                margin-top: 24px;
                            }
    
                            &__youtube{
                                display: flex;
                                margin-left: 35px;
                                align-items: center;
                                cursor: pointer;
                                text-decoration: none;
                                display: none;

                                &_img {
                                    display: none;
                                }
                    
                                &_text{
                                    color: #fff;
                                    font-size: 24px;
                                    margin-left: 18px;
                                }
                            }
                        }
                    }
    
                    &__right{
                        
                        display: none;
    
                        &_img{
                            width: 564px;
                            height: 372px;
                            border-radius: 1px;
                        }
    
                        &_play{
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%)
                        }
                    }
                }
            }
    
            &__content{
                margin-top: 32px;
                flex-direction: column;
    
                &__investigation{
                    flex-direction: column;
                }
            }
        }
    }
}