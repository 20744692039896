.projects{
    margin-top: 0px;
    position: relative;
    &__wrappersProjects {
        width: 100%;
        justify-content: space-between;
    }
    &__secondBlock {
        width: 100%;
        margin-top: 114px;
    }
    &_img {
        position: absolute;
        display: block;
        z-index: -100;
        right: 100px;
        top: -170px;
        width: 540px;
        height: 540px;
    }
    &_imgFist {
        position: absolute;
        display: block;
        z-index: -100;
        left: -100px;
        top: 450px;
        width: 320px;
        height: 620px;
    }
    &_imgCourt {
        position: absolute;
        display: block;
        z-index: -100;
        right: -100px;
        top: 1300px;
        width: 620px;
        height: 880px;
    }
}

@media only screen and (max-width: 500px) {
    .projects{

        &__wrappersProjects {
            width: 100%;
            justify-content: space-between;
        }
        &__secondBlock {
            width: 100%;
            margin-top: 40px;
        }
        &_img, &_imgFist, &_imgCourt {
            display: none;
        }
    }
}