.crypto{
    width: 48%;
    flex-direction: column;
    align-items:center;
    position: relative;

    &__blockCurrency{
        flex-direction: column;
        width: 100%;

        &__item{

            width: 100%;
            justify-content: space-between;
            margin-top: 30px;
            align-items: center;

            &_leftPart {
                align-items: flex-end;
                display: flex;
            }

            &_title {
                font-size: 24px;
                margin: 0px 6px 0px 12px;
            }

            &_subTitle {
                font-size: 18px;
                color: #828282;
                text-transform: uppercase;
            }

            &_address {
                font-size: 18px;
                margin-right: 20px;
                display: flex;
                align-items:flex-start;
                justify-content: flex-start;
                width: 460px;
            }

            &__buttons{

                flex-direction: row;
                align-items: center;

                &_img{
                    width: 33px;
                    height: 45px;
                    cursor: pointer;
                }

                &_qr{
                    width: 54px;
                    height: 54px;
                    margin-left: 24px;
                    display: block;
                    cursor: pointer;
                }

                &_fullScreen {
                    position: absolute;
                    display: flex;
                    top: 0;
                    left: 0;
                    width: 100vw;
                    height: 100vh;
                    z-index: 10000;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    background-color: rgba(0,0,0, 0.7);

                    &_close {
                        display: none;
                    }

                    &_img {
                        height: 60Vh;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .crypto{
        overflow: hidden;
    
        &__blockCurrency{
    
            &__item{

                justify-content: flex-start;
    
                &_title{

                    font-size: 22px;
                    color:#000
                }

                &_leftPart {
                    align-items: flex-end;
                    display: flex;
                }

                &_title {
                    font-size: 16px;
                    margin: 0px;
                }

                &_subTitle {
                    font-size: 18px;
                    color: #828282;
                    text-transform: uppercase;
                    display: none;
                }

                &_address {
                    font-size: 12px;
                    margin-left: 12px;
                    width: auto;
                }
    
                &__buttons{

                    flex-direction: row;

                    &_img{
                        width: 28px;
                        display: none;
                    }
                    &_qr{
                        display: none;
                    }
                }
            }
        }
    }
}