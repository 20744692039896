.database{
    margin-top: 114px;
    flex-direction: column;
    display: flex;

    &_title{
        display: block;
    }

    &__wrapper{
        margin-top: 40px;
        width: 100%;
        height: 310px;
        background: linear-gradient(45deg, #0F1627, #202A43, #242A3E);

        &__block{
            position: relative;
            height: 310px;
            flex-direction: column;
            justify-content: center;
            align-items: baseline;

            &__mobile {
            
                &_header {
                    display: none;
                    color: #E63946;
                    font-size: 24px;
                    font-weight: 600;
                    text-align: center;
                    margin-top: 25px;
                }

                &_subHeader {
                    display: none;
                }
            }

            &_title {
                display: none;
            }

            &_img{
                position: absolute;
                bottom: 0;
                right: -100px;
                height: 415px;
            }
            &_text{
                width: 750px;
                font-size: 18px;
                font-weight: 300;
                color: #fff;
                display: block;
            }
            &__buttons{
                margin-top: 24px;
                align-items: center;

                &_fillButton{
                    min-width: 256px;
                    height: 48px;
                    font-weight: 400;
                    background-color: #E63946;
                }

                &_fillButton:hover{
                    background: linear-gradient(86.22deg, #E63946 0%, #AA2731 133.42%);
                }

                &_fillButton:active{
                    background: #AA2731;
                }

                &_withoutButton{
                    min-width: 256px;
                    height: 48px;
                    border: solid 1px #fff;
                    background-color: inherit;
                    align-items: center;
                    justify-content: center;
                    font-size: 18px;
                    color: #fff;
                    // margin-left: 40px;
                    cursor: pointer;
                    display: flex;
                    text-decoration: none;
                    font-weight: 400;
                }

                &_withoutButton:hover{
                    background: rgba(255, 255, 255, 0.2);
                }

                &_withoutButton:active{
                    background: rgba(255, 255, 255, 0.4);
                }
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .database {

        margin-top: 0px;

        &_title {
            display: none;
        }

        &__wrapper {

            height: auto;
        
            &__block {
                
                padding: 10px 0px 30px;
                height: auto;
                display: flex;
                align-items: center;

                &_title {
                    display: block;
                    color: #fff;
                    font-size: 24px;
                    font-weight: 400;
                    font-family: 'Oswald';
                }

                &_text {
                    display: none;
                }
    
                &_img {
                    display: flex;
                    position: relative;
                    height: auto;
                    width: 100%;
                    right: 0px;
                }

                &__mobile {

                    &_header {
                        display: flex;
                        color: #E63946;
                        font-size: 24px;
                        font-weight: 600;
                        text-align: center;
                        margin-top: 25px;
                    }

                     &_subHeader {
                        display: flex;
                        color: #fff;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                        text-align: center;
                        margin-top: 25px;
                    }
                }
            }
        }
    }
}