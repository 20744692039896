.item{
    margin-top: 50px;
    flex-direction: column;
    width: 495px;
    margin-right: 32px;
    cursor: pointer;
    color: black;
    text-decoration: none;

    &_img{
        width: 495px;
        height: 290px;
    }

    &_title{
        font-size: 24px;
        margin-top: 20px;
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {
    .item{
        margin-top: 24px;
        flex-direction: column;
        width: calc(100vw - 40px);
        margin-right: 32px;
        cursor: pointer;
        color: black;
        text-decoration: none;
    
        &_img{
            width: calc(100vw - 40px);
            height: 290px;
        }
    
        &_title{
            font-size: 24px;
            margin-top: 20px;
            width: 100%;
        }
    }
}