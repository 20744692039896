.footer{
    margin-top: 80px;
    width: 100%;
    min-height: 335px;
    background: linear-gradient(45deg, #0F1627, #202A43, #242A3E);

    &__block{
        margin-top: 30px;
        font-weight: 300;
        justify-content: flex-start;
        align-items: center;

        &_image{
            width: 55px;
            height: 55px;
        }

        &_title{
            font-size: 24px;
            color: #fff;
            margin-left: 20px;
        }
    }

    &__info{
        margin-top: 40px;
        color: #F4F4F4;
        justify-content: space-between;
        
        &_basic{
            display: flex;
            font-weight: 300;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: -0.03em;
            width: 25%;
        }

        &__topic{
            flex-direction: column;

            &_title{
                letter-spacing: -0.01em;
                font-weight: 500;
                font-size: 24px;
                line-height: 32px;
                margin-bottom: 16px;
                color: #FFFFFF;
                cursor: pointer;
                text-decoration: none;

                &_inactive {
                    color: grey;
                    cursor: default;
                }
            }

            &_title:hover{
                color: #E63946;
            }

            &_descr{
                letter-spacing: -0.01em;
                font-weight: 300;
                font-size: 18px;
                line-height: 32px;

                &_inactive {
                    color: grey;
                    cursor: default;
                }
            }
        }
    }

    &__social{
        margin: 56px 0px 32px 0px;
        justify-content: center;

        &_img{
            margin: 0px 15px;

            &_fb, &_inst, &_tube, &_telega{
                fill: #F4F4F4
            }

            &_fb:hover{
                fill: #4267B2;
            }

            &_inst:hover{
                fill: #E63946;
            }

            &_tube:hover{
                fill: #E63946;
            }

            &_telega:hover{
                fill: #29A3EF;
            }
        }

        &_img:hover{
            cursor: pointer;
        }
    }
}

@media only screen and (max-width: 500px) {
    .footer{
        margin-top: 40px;
    
        &__block{
            margin-top: 30px;
        }
    
        &__info{
            margin-top: 0px;
            color: #F4F4F4;
            justify-content: space-between;
            flex-direction: row;
            width: 100%;
            flex-wrap: wrap;
            
            &_basic{
                display: none;
            }
    
            &__topic{
                flex-direction: column;
                margin-top: 32px;
                width: 50%;
    
                &_title{
                    letter-spacing: -0.01em;
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 32px;
                    margin-bottom: 16px;
                    color: #FFFFFF;
                    cursor: pointer;

                    &_inactive {
                            color: grey;
                            cursor: default;
                        }
                }
    
                &_title:hover{
                    color: #E63946;
                }
    
                &_descr{
                    letter-spacing: -0.01em;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 32px;
                }
            }
        }
    
        &__social{
            margin: 56px 0px 32px 0px;
            justify-content: center;
    
            &_img{
                margin: 0px 24px;
            }
        }
    }
}