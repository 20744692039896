.investigation{
    width: 100%;

    &__wrapperVideoCover {
    
        justify-content: center;
        align-items: center;
        border-width: 1px;
        border-style: solid;
        border-image: linear-gradient(120deg, #E63946, rgba(230, 57, 70, 0)) 0.5;

        &_playVideo {
            width: 102px;
            height: 102px;
            border: 3px white solid;
            border-radius: 100px;

            path {
                fill: white;
            }
        }
    }

    &__wrapperVideoCover:hover .investigation__wrapperVideoCover_playVideo {

        border: 3px #E63946 solid;

        path {
            fill: #E63946;
        }
    }

    &__wrapper{

        flex-direction: column;

        &__main{
            width: 100%;
            height: 680px;
            align-items: center;
            z-index: 10;
            position: relative;

            &_bg{
                z-index: -1;
                background: linear-gradient(90deg, #0F1627 0%, #202A43 52.08%, #242A3E 100%);
                opacity: 0.75;
                width: 100%;
                height: 680px;
                position: absolute;
            }

            &__head{
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                align-items: center;

                &__left{

                    flex-direction: column;
                    width: 50%;

                    &__wrapper {
                        flex-direction: column;
                        justify-content: space-between;
                    }

                    &__title{
                        justify-content: space-between;
                        align-items: center;
    
                        &_h2{
                            font-weight: 400;
                            font-size: 32px;
                            line-height: 47px;
                            display: flex;
                            align-items: center;
                            color: #FFFFFF;
                            margin: 0px;
                        }
                    }
    
                    &_devider{
                        height: 5px;
                        background-color: #E63946;
                        margin-top: 17px;
                        width: 100%;
                    }
    
                    &_text{
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 28px;
                        letter-spacing: 0.01em;
                        color: #FFFFFF;
                        margin-top: 24px;
                        width: 100%;
                    }

                    &__btn{

                        margin-top: 36px;
                        cursor: pointer;
                        width:fit-content;
                        padding: 12px;
                        text-decoration: none;
                        display: flex;

                        &_secondary {
                            display: none;
                        }

                        &_primary{
                            font-weight: 300;
                            font-size: 24px;
                            line-height: 29px;
                            color: #FFFFFF;
                            text-transform: lowercase;
                            background-color: inherit;
                            display: flex;
                            flex-direction: row;

                            &_text{
                                margin-left: 18px;
                            }

                            &_text::first-letter{
                                text-transform: uppercase;
                            }
                        }

                        &_primary:hover .investigation__wrapper__main__head__left__btn_primary_text{
                            color: #E63946;
                        }

                        &_primary:hover .investigation__wrapper__main__head__left__btn_primary_img {
                            stroke: #E63946;
                        }
                    }
                }

                &__right{
                    
                    position: relative;
                    cursor: pointer;
                    display: flex;
                }
            }
        }

        &__content{
            margin-top: 80px;
            flex-direction: column;

            &_parser{
                flex-direction: column;
                display: flex;
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .investigation{
    
        &__wrapper{
    
            &__main{
                width: 100%;
                height: calc(100vh - 40px);
                align-items: center;
                z-index: 10;
                position: relative;
    
                &_bg{
                    z-index: -1;
                    background: linear-gradient(90deg, #0F2027 0%, #203A43 52.08%, #242A3E 100%);
                    opacity: 0.75;
                    width: 100%;
                    height: 680px;
                    position: absolute;
                }
    
                &__head{
                    flex-direction: row;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
    
                    &__left{
    
                        flex-direction: column;
                        width: 100%;
                        justify-content: space-between;
                        height: calc(100vh - 320px);

                        &__wrapper {
                            flex-direction: column;
                            justify-content: space-between;
                        }
    
                        &__title{
                            justify-content: space-between;
                            align-items: center;
        
                            &_h2{
                                font-weight: 400;
                                font-size: 26px;
                                line-height: 38px;
                                display: flex;
                                align-items: center;
                                color: #FFFFFF;
                                margin: 0px;
                            }
                        }
        
                        &_devider{
                            height: 5px;
                            background-color: #E63946;
                            margin-top: 17px;
                            width: 100%;
                        }
        
                        &_text{
                            font-weight: 400;
                            font-size: 22px;
                            line-height: 28px;
                            letter-spacing: 0.01em;
                            color: #FFFFFF;
                            margin-top: 24px;
                            width: 100%;
                        }
    
                        &__btn{
    
                            margin-top: 36px;
                            cursor: pointer;
                            width:fit-content;
                            padding: 12px;
                            text-decoration: none;
                            display: none;

                            &_secondary {
                                display: block;
                                width: 100%;
                            }
    
                            &_primary{
                                font-weight: 300;
                                font-size: 24px;
                                line-height: 29px;
                                color: #FFFFFF;
                                text-transform: lowercase;
                                background-color: inherit;
                                display: flex;
                                flex-direction: row;
    
                                &_text{
                                    margin-left: 18px;
                                }
    
                                &_text::first-letter{
                                    text-transform: uppercase;
                                }
                            }
    
                            &_primary:hover .investigation__wrapper__main__head__left__btn_primary_text{
                                color: #E63946;
                            }
    
                            &_primary:hover .investigation__wrapper__main__head__left__btn_primary_img {
                                stroke: #E63946;
                            }
                        }
                    }
    
                    &__right{
                        
                        position: relative;
                        cursor: pointer;
                        display: none;
                    }
                }
            }
    
            &__content{
                margin-top: 80px;
                flex-direction: column;
    
                &_parser{
                    flex-direction: column;
                    display: flex;
                }
            }
        }
    }
}